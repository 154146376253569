import * as Styled from './styled';

import { Router, Trans } from 'i18n';
import { useCallback, useEffect } from 'react';

import Container from 'components/Shared/Layout/Container';
import SearchBox from 'components/Shared/SearchBox';
import SlickSlider from 'react-slick';

const Slider = ({ property }) => {
  useEffect(() => {
    document.getElementsByClassName('placeholder')[0].style.display = 'none';
    document.getElementsByClassName('slick-track')[0].style.display = 'block';
  }, []);

  const handleSearchFormSubmit = useCallback(values => {
    Router.push({
      pathname: '/rezerwacja',
      query: values,
    });
  }, []);

  return (
    <Styled.SliderContainer>
      <Styled.ImagePlaceholder className="placeholder">
        <img alt="Sun Seasons 24" src="/static/images/property/1.jpg" />
      </Styled.ImagePlaceholder>
      <SlickSlider
        arrows={false}
        autoplaySpeed={5000}
        css={Styled.slider}
        dotsClass="resource-slider-dots slick-dots"
        easing="swing"
        slidesToScroll={1}
        slidesToShow={1}
        speed={1000}
        autoplay
        dots
        infinite
        lazyLoad
        swipe
      >
        <img alt="Sun Seasons 24" src="/static/images/property/1.jpg" />
        <img alt="Sun Seasons 24" src="/static/images/property/4.jpg" />
        <img alt="Sun Seasons 24" src="/static/images/property/3.jpg" />
      </SlickSlider>
      <Styled.TextContainer>
        <Container>
          <Styled.H3>{property.name}</Styled.H3>
          <Trans i18nKey="heroTitle">
            <Styled.H1>
              Wyjątkowe apartamenty
              <br />w centrum Karpacza
            </Styled.H1>
          </Trans>
        </Container>
      </Styled.TextContainer>
      <Styled.SearchContainer>
        <Container>
          <SearchBox type="inline" onSubmit={handleSearchFormSubmit} />
        </Container>
      </Styled.SearchContainer>
    </Styled.SliderContainer>
  );
};

export default Slider;
