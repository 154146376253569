import { ApartmentList, Container, H1 } from './styles';
import { shallowEqual, useSelector } from 'react-redux';

import Helmet from 'react-helmet';
import Layout from 'components/Layout';
import Slider from './Slider/index';
import apartments from 'modules/apartments';
import app from 'modules/app';
import getConfig from 'next/config';
import { useTranslation } from 'i18n';

const { publicRuntimeConfig } = getConfig();

const Apartment = apartments.components.Apartment;

const Home = () => {
  const { t } = useTranslation(['common']);
  const [language, inactiveLanguages] = app.hooks.useLanguage();
  const property = useSelector(app.selectors.getProperty, shallowEqual);
  const featuredApartments = useSelector(apartments.selectors.getFeaturedApartments, shallowEqual);

  return (
    <>
      <Helmet>
        <html lang={language} />
        <link href={`${publicRuntimeConfig.host}/${language}`} rel="canonical" />
        {inactiveLanguages.map(inactiveLanguage => (
          <link
            key={inactiveLanguage}
            href={`${publicRuntimeConfig.host}/${inactiveLanguage}`}
            hrefLang={inactiveLanguage}
            rel="alternate"
          />
        ))}
      </Helmet>
      <Layout>
        <Slider property={property} />
        <Container>
          <H1>{t('featuredApartments')}</H1>
          <ApartmentList>
            {featuredApartments.map(apartment => (
              <Apartment.FeaturedItem key={apartment.name} apartment={apartment} />
            ))}
          </ApartmentList>
        </Container>
      </Layout>
    </>
  );
};

export default Home;
