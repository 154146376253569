import _Container from 'components/Shared/Layout/Container';
import { breakpoints } from 'styles/config';
import styled from 'styled-components';

export const ApartmentList = styled.div`
  display: flex;
  margin-bottom: 6rem;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Container = styled(_Container)`
  padding-top: 3rem;

  ${breakpoints.tabletLandscapeUp} {
    padding-top: 9rem;
  }
`;

export const H1 = styled.h1`
  font-size: 4rem;
  font-weight: 200;
  text-align: center;
  margin-bottom: 3rem;
`;
