import { breakpoints, colors } from 'styles/config';

import styled from 'styled-components';

const headings = `
  color: ${colors.white};
  font-weight: 400;
  text-align: center;
  text-shadow: 0 0 2rem rgba(0, 0, 0, 0.8);
  pointer-events: none;
  z-index: 2;

  ${breakpoints.tabletLandscapeUp} {
    text-shadow: 0 0 4rem rgba(0, 0, 0, 1);
  }
`;

export const H1 = styled.h1`
  display: none;
  ${headings}

  ${breakpoints.tabletLandscapeUp} {
    display: block;
    font-size: 4.5rem;
    line-height: 4.7rem;
  }

  ${breakpoints.desktopUp} {
    font-size: 7rem;
    line-height: 7rem;
  }
`;

export const H3 = styled.h3`
  color: ${colors.white};
  font-size: 2.4rem;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.39rem;
  ${headings}

  ${breakpoints.tabletLandscapeUp} {
    margin-bottom: 1rem;
    font-size: 1.8rem;
  }

  ${breakpoints.desktopUp} {
    margin-bottom: 2rem;
  }
`;

export const overlay = `
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2.5);
    z-index: 1;
    pointer-events: none;
  }
`;

export const slider = `
  display: none;
  ${overlay}

  ${breakpoints.tabletLandscapeUp} {
    display: block;
  }
`;

export const ImagePlaceholder = styled.div`
  position: relative;
  display: none;
  height: auto;
  line-height: 0;
  ${overlay}

  ${breakpoints.tabletLandscapeUp} {
    display: block;
  }
`;

export const SearchContainer = styled.div`
  display: block;
  width: 100%;
  z-index: 10;

  ${breakpoints.tabletLandscapeUp} {
    position: absolute;
    bottom: -3.5rem;
    left: 0;
  }
`;

export const SliderContainer = styled.section`
  display: block;
  position: relative;

  .resource-slider-dots {
    position: absolute;
    top: calc(45% + 7rem + 1rem);

    ${breakpoints.desktopUp} {
      top: calc(45% + 9rem + 5rem);
    }
  }
`;

export const TextContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 6rem 0;
  pointer-events: none;
  background: url(/static/images/property/1.jpg) no-repeat center center;
  background-size: cover;
  margin-bottom: 2rem;
  z-index: 10;

  ${breakpoints.tabletLandscapeUp} {
    position: absolute;
    top: 45%;
    left: 0;
    margin-bottom: 0;
    margin-top: -7rem;
    padding: 0;
    background: none;

    &:before {
      content: none;
    }
  }

  ${breakpoints.desktopUp} {
    top: 45%;
    margin-top: -9rem;
  }
`;
