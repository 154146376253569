import 'react-dates/initialize';

import { i18n, withTranslation } from 'i18n';

import Helmet from 'react-helmet';
import app from 'modules/app';
import home from 'modules/home';

const Home = home.components.Page;

Home.getInitialProps = async ctx => {
  const { req, asPath, store } = ctx;

  const language = req ? req.language : i18n.language;

  if (req && !process.browser) {
    Helmet.renderStatic();
  }

  await store.dispatch(app.actions.loadAppData(req));

  return { asPath, language, namespacesRequired: ['common', 'apartments'] };
};

export default withTranslation(['common', 'apartments'])(Home);
